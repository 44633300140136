import { useEffect, useState } from "react"
import { useAppState } from "../../globalStates/AppState"
import CrsTabs, { TabItemType } from "../../ui/CrsTabs"
import { useMeetingController } from "../context/MeetingController"
import RosterAttendees from "./RosterAttendees.tsx/RosterAttendees"
import { RosterContentClosed, RosterContentOpen, RosterRoot } from "./Roster.styled"
import RosterAttendeesClosed from "./RosterAttendeesClosed/RosterAttendeesClosed"
import { IconChat, IconParticipants } from "../../ui/Icons"
import branding from "../../branding/branding"
import ChatPage, { calcConversationId, ChatConversationParam, DisplayStyle } from "../../communicationArea/ChatPage"
import { ConversationType } from "../../API"
import { useParams } from "react-router-dom"
import { SideBarViewMode } from "../../conference/components/Roster"
import { RosterToggleButton } from "./RosterToggleButton/RosterToggleButton"
enum TabKey {
    PARTICIPANTS = "participants",
    CHAT = "chat"
}

interface RosterProps {}

function Roster(props: RosterProps) {
    const { isRosterOpen, setIsRosterOpen, isMeetingChatShown, setIsMeetingChatShown } = useAppState()
    const [activeTab, setActiveTab] = useState(TabKey.PARTICIPANTS)
    const meetingController = useMeetingController()
    const { roomName }: any = useParams()
    const tabItemTypes: TabItemType[] = [
        {
            label: branding.conferenceTexts.participantsSwitch,
            value: "participants",
            icon: <IconParticipants fill={"#999999"} width={"18px"} height={"18px"} />
        },
        {
            label: branding.conferenceTexts.chatSwitch,
            value: "chat",
            icon: <IconChat fill={"#999999"} width={"17px"} height={"17px"} />
        }
    ]

    useEffect(() => {
        if (meetingController.getMeetingKind() === "call") {
            tabItemTypes.filter((tabItemType: TabItemType) => tabItemType.value !== "chat")
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isMeetingChatShown && meetingController.getMeetingKind() !== "call") {
            setActiveTab(TabKey.CHAT)
        }
        // eslint-disable-next-line
    }, [isRosterOpen, isMeetingChatShown])

    return (
        <RosterRoot isOpen={isRosterOpen}>
            <RosterToggleButton isOpen={isRosterOpen} onClick={() => setIsRosterOpen(!isRosterOpen)} />
            {isRosterOpen && (
                <CrsTabs
                    width={"100%"}
                    centerItems={true}
                    hideControls={true}
                    tabItemFontSize={"14px"}
                    tabItemPadding={meetingController.getMeetingKind() === "call" ? 350 : 160}
                    tabItems={tabItemTypes}
                    activeItem={activeTab}
                    onTabItemClicked={(tabKey) => {
                        setActiveTab(tabKey as TabKey)
                        setIsMeetingChatShown(tabKey === "chat")
                    }}
                    style={{
                        backgroundColor: "transparent",
                        padding: "0",
                        height: "45px"
                    }}
                    borderBottomColor={"#727272"}
                />
            )}
            {isRosterOpen ? (
                <RosterContentOpen>
                    {activeTab === TabKey.PARTICIPANTS && <RosterAttendees />}
                    {activeTab === TabKey.CHAT && (
                        <ChatPage
                            displayStyle={DisplayStyle.DEFAULT_DARK}
                            param={ChatConversationParam.conversationByConversationId(
                                ConversationType.PUBLIC,
                                calcConversationId(roomName)
                            )}
                            viewMode={SideBarViewMode.ROSTER}
                            virtualCafe={meetingController.getMeetingKind() === "virtualCafe"}
                        />
                    )}
                </RosterContentOpen>
            ) : (
                <RosterContentClosed>
                    <RosterAttendeesClosed />
                </RosterContentClosed>
            )}
        </RosterRoot>
    )
}

export default Roster
