import styled from "styled-components"

export const RosterRoot = styled.div<{ isOpen: boolean | null | undefined }>`
    width: ${(props) => (props.isOpen ? "350px" : "65px")};
    background-color: #313233;
    position: relative;
    transition: 0.3s;
    z-index: 10;
`

export const RosterContentOpen = styled.div`
    overflow: scroll;
    height: calc(100vh - 100px);
    flex-grow: 2;
`

export const RosterContentClosed = styled.div``

export const RosterToggleButtonStyled = styled.div`
    position: absolute;
    top: 45%;
    left: -20px;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    background-color: #313233;
`
export const RosterToggleIcon = styled.div<{ isOpen?: boolean | null | undefined }>`
    position: absolute;
    top: 50px;
    left: 5%;
    cursor: pointer;
    transform-origin: center;
    transform: ${(props) => (props.isOpen ? "" : "scaleX(-1)")};
    transition: 0.3s;
    transition-delay: 0.5s;
    height: 15px;
    width: 15px;
`
