import { IconArrowHeadRight } from "../../../ui/Icons"
import { RosterToggleButtonStyled, RosterToggleIcon } from "../Roster.styled"

interface RosterToggleButtonProps {
    onClick: () => void
    isOpen: boolean | undefined | null
}

export const RosterToggleButton = (props: RosterToggleButtonProps) => {
    return (
        <RosterToggleButtonStyled>
            <RosterToggleIcon onClick={props.onClick} isOpen={props.isOpen}>
                {IconArrowHeadRight({ fill: "#fff", height: "15px", width: "15px" })}
            </RosterToggleIcon>
        </RosterToggleButtonStyled>
    )
}
