import { ColorString } from "@corussoft/branding-generator"
import { ThirdPartySettingsKoelnmesseBranding } from "./koelnmesse/ThirdPartySettingsKoelnmesseBranding"

export enum ThirdPartySettingsType {
    KOELNMESSE = "KOELNMESSE",
    NONE = "NONE"
}

export interface SettingsTabBranding {
    /**
     * @title settingsAreaRowColor
     * @description settingsAreaRowColor
     * @title_de settingsAreaRowColor
     * @description_de settingsAreaRowColor
     */
    settingsAreaRowColor: ColorString
    /**
     * @title Timezone margin
     * @description Margin above the timezone picker
     * @title_de Zeitzonen Abstand
     * @description_de Abstand über der Zeitzonen Auswahl
     */
    timezonePickerRowMarginTop: string
    /**
     * @title ThirdPartySettings Type
     * @description Type of third party settings
     * @title_de ThirdPartySettings Typ
     * @description_de Typ der zu nutzenden ThirdParty Einstellungen
     */
    thirdPartySettingsType: "KOELNMESSE"
    /**
     * @title ThirdPartySettings Koelnmesse
     * @description Configuration options for Koelnmesse
     * @title_de ThirdPartySettings Koelnmesse
     * @description_de Konfiguration für Koelnmesse
     */
    thirdPartySettingsKoelnmesse: ThirdPartySettingsKoelnmesseBranding
}
