import styled from "styled-components"

export const LiveIndicatorRoot = styled.div<{ isLive: boolean }>`
    background-color: ${(props) => (props.isLive ? "rgba(255, 255, 255, 0.5)" : "rgba(114, 114, 114, 0.5)")};
    border-radius: 3px;
    color: ${(props) => (props.isLive ? "#000" : "#fff")};
    padding: 3px;
    text-align: center;
    width: auto;
    max-width: 150px;
    height: 30px;
    font-size: 16px;
    line-height: 23px;
`
