// #region GLobal layout

import styled, { css } from "styled-components"
import branding from "../../../branding/branding"
import { device } from "../../../utils/Device"
import { Col, Row } from "react-bootstrap"

export const ContextMenuWrapper = styled.div<{ showShadow?: boolean }>`
    width: 100%;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "none")};
    position: relative;
    z-index: 10;
    padding-bottom: 15px;
`

export const ContextMenuRoot = styled.div`
    padding: 0 25px;
    max-width: 1300px;
    margin: auto;
    white-space: nowrap;
    font-size: 14px;

    @media (max-width: 1200px) {
        font-size: 12px;
    }

    @media (max-width: 1000px) {
        font-size: 10px;
    }
`

export const EventDateTitle = styled.div`
    white-space: pre-line;
    color: ${branding.personDetailPageContent.personDetailPageSpeakerTitlePositionColor ?? "#000"};
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin-top: 10px;

    @media ${device.mobile} {
        font-size: 14px;
    }
`

export const EventDateTimeLocation = styled.div`
    white-space: pre-line;
    color: ${branding.personDetailPageContent.personDetailPageSpeakerTitlePositionColor ?? "#000"};
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-top: 5px;
`

export const EventDateDescription = styled.p`
    white-space: pre-line;
    color: ${branding.personDetailPageContent.personDetailPageSpeakerTitlePositionColor ?? "#000"};
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 17px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 14px; /* fallback */
    max-height: 28px; /* fallback */
`
//#endregion

// #region  Person EventDates

export const ContentRow = styled(Row)`
    position: relative;
    height: auto;
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    background: #fff;
    margin-left: 0;
    margin-right: 0;
    padding: 15px 10px;

    @media ${device.laptop} {
        margin-left: 0px;
    }
`

export const EventDateContentCol = styled(Col)`
    padding: 20px 0;
`

export const EventDateHeaderPositionRow = styled(Row)<{ display?: string }>`
    margin: 0 0 20px 0;
    width: 95%;

    @media ${device.laptop} {
        margin: 30px 0 10px 0;
        ${(props) => (props.display ? `display: ${props.display};` : "")}
    }
`

export const EventDatesTitle = styled.div`
    font-family: ${branding.font1};
    font-weight: bold;
    font-size: 16px;
    line-height: 18.75px;
    align-self: flex-start;

    @media ${device.laptop} {
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;
    }
`

export const EventDatesFavorite = styled.p`
    font-family: ${branding.font1};
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #727272;
    cursor: pointer;
`

export const EventDatesTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
`

export const EventDateViewRoot = styled.div<{ contextMenuOpened: boolean }>`
    margin-bottom: -20px;

    @media ${device.mobile} {
        margin-bottom: ${(props) => (props.contextMenuOpened ? "-20px" : "-40px")};
    }
`
export const EventDateViewRow = styled(Row)`
    display: inline-flex;
    width: 100%;
    margin-right: 0px;

    @media ${device.mobile} {
        width: calc(100% + 40px);
    }
`
export const EventDateViewIconCol = styled(Col)`
    display: flex;
    align-items: center;
`
export const EventDateViewInfoCol = styled(Col)`
    display: flex;
    align-items: flex-start;
    flex: auto;

    @media ${device.mobile} {
        margin-left: 15px;
        margin-top: -15px;
    }
`
export const EventDateViewActionsCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const EventDateViewLogoDiv = styled.div`
    width: 95px;
    height: 95px;
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    overflow: hidden;
    margin-right: 20px;

    @media ${device.mobile} {
        width: 60px;
        height: 60px;
    }
`
// #endregion

// #region  Share and bookmark icons component
export const ShareIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
`

export const IconRootArea = styled.div`
    color: ${branding.mainInfoColor ?? "#000"};
    display: flex;
`

export const ShareBookmarkButton = styled.button`
    flex: 0 0 auto;
    margin-bottom: 20px;
    padding: 7px;
    height: auto;
    min-height: 45px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.sideIconBar.sideIconColorDark};
    color: ${branding.sideIconBar.sideIconColorDark};
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
    min-width: 212px;
    width: auto;
    outline: none;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        margin-bottom: 0px;
    }

    @supports (-moz-appearance: none) {
        margin-bottom: 20px;
    }

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

export const ShareBookmarkButtonIcon = styled.div`
    display: inline-block;
    float: left;
    padding-left: 10px;
    margin-top: 2px;
`

export const ShareBookmarkButtonText = styled.div`
    display: inline-block;
    position: relative;
    padding-left: 10px;
    top: 50%;
    transform: translateY(-40%);
`

export const ShareBookmarkButtonsRoot = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        margin-bottom: 20px;
    }
`
// #endregion

// #region  View more button component
export const ViewMoreButtonContainer = styled.div`
    float: right;
    margin-top: -5px;
    margin-right: 10px;
`

export const ViewMoreButtonTextContainer = styled.div`
    font-size: 12px;
    display: inline-block;
`

export const ViewMoreButtonIconContainer = styled.div`
    display: inline-block;
    margin-left: 5px;
`
// #endregion
