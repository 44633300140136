import {
    MeetingStatus,
    useLocalVideo,
    useMeetingManager,
    useMeetingStatus,
    useRosterState
} from "amazon-chime-sdk-component-library-react"
import { createContext, useCallback, useEffect, useState } from "react"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { AttendeeData, getAttendeeInfo } from "../../backendServices/MeetingServices"
import branding from "../../branding/branding"
import { defaultLogger as logger, useAppState } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import TopBar from "../../navigationArea/TopBar"
import { IconBannedFromMeeting, IconBlockContact, IconLiveStream, IconPhone, IconRemovedFromMeeting } from "../../ui/Icons"
import ControlBar from "../components/ControlBar/ControlBar"
import Timer from "../components/Timer/Timer"
import Roster from "../Roster/Roster"
import {
    BottomPanel,
    BreadcrumbRoot,
    ConferenceRoomContainer,
    ConferenceRoomMainContainer,
    Local,
    MainContent,
    Remote
} from "./ConferenceRoom.styled"
import Stage from "../components/Stage/Stage"
import LocalTile from "../components/LocalTile/LocalTile"
import {
    findCalendarEntryById,
    getRaisedHandsByChimeMeetingId,
    listUsersInAllLoungesOrCalls
} from "../../backendServices/GraphQLServices"
import { API, graphqlOperation } from "aws-amplify"
import { onRaisedHandCreated, onRaisedHandDeleted } from "../../graphql/subscriptions"
import Breadcrumb, { BreadcrumbItem } from "../../navigationArea/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import { useLanguageState } from "../../globalStates/LanguageState"
import { generateBreadCrumb } from "../utils/generateBreadcrumb"
import { getExternalMeetingIdFromURL } from "../utils/getExternalMeetingIdFromURL"
import { Spinner } from "react-bootstrap"
import MeetingStatusMessage from "./MeetingStatusMessage/MeetingStatusMessage"
import { useMeetingController } from "../context/MeetingController"
import { EGMeetingStatus } from "../enums/EGMeetingStatus"
import { useMeetingInvitation } from "../context/MeetingInvitation"
import GreenRoomControls from "../components/GreenRoomControls/GreenRoomControls"
import { useGreenRoomContext } from "../context/GreenRoomContext"
import { InvitationType, InviteStatus } from "../../API"
import { usePreMeetingSettingContext } from "../context/PreMeetingSettingsContext"
import { LocalTileVisibilityToggleButton } from "../components/LocalVideo/LocalTileVisibilityToggleButton"
export const RaisedHandContext = createContext<string[]>([])

const ConferenceRoom = () => {
    const meetingManager = useMeetingManager()
    const meetingController = useMeetingController()
    const loggedInUser = useLoggedInState()
    const [raisedHands, setRaisedHands] = useState<any>(null)
    const { getExternalMeetingId } = useMeetingController()
    const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItem[]>([])
    const { roomName }: any = useParams()
    const lang = useLanguageState().getLanguage()
    const { getEGMeetingStatus } = useMeetingController()
    const meetingStatus = useMeetingStatus()
    const loggedInState = useLoggedInState()
    const loggedInUserId = loggedInState.user()?.profileId || ""
    const { roster } = useRosterState()
    const [attendees, setAttendees] = useState(Object.values(roster).filter((attendee: any) => attendee.role !== "recorder"))
    const { isVideoEnabled, toggleVideo } = useLocalVideo()
    const meetingInvitation = useMeetingInvitation()
    const greenRoom = useGreenRoomContext()
    const history = useHistory()
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const appState = useAppState()
    const [isLocalTileVisibleToUser, setIsLocalTileVisibleToUser] = useState<boolean>(true)

    useEffect(() => {
        const meetingRoomHasChatInRoster =
            meetingController.getMeetingKind() === "conferenceroom" ||
            meetingController.getMeetingKind() === "roundtable" ||
            meetingController.getMeetingKind() === "virtualCafe" ||
            meetingController.getMeetingKind() === "greenroom" ||
            meetingController.getMeetingKind() === "showroom"
        if (meetingRoomHasChatInRoster && appState.isNetworkingOpen() && meetingStatus === MeetingStatus.Succeeded) {
            appState.toggleNetworking()
        }
        // eslint-disable-next-line
    }, [meetingStatus, meetingController.getMeetingKind()])

    /** Effects of ConfrerenceRoom */

    useEffect(() => {
        setAttendees(Object.values(roster).filter((attendee: any) => attendee.role !== "recorder"))
    }, [roster])

    // TODO: Should be reusable. Same is used in ConferenceOverlayV2 room -> refactor it
    useEffect(() => {
        /** Load raised hands when initialy entering a conference room */
        getRaisedHandsByChimeMeetingId(getExternalMeetingId() as string).then((data: any) => {
            setRaisedHands(
                data.data.listRaisedHands.items.map((item: any) => {
                    return item.id
                })
            )
        })
        // eslint-disable-next-line
    }, [getExternalMeetingId()])

    // TODO: Should be reusable. Same is used in ConferenceOverlayV2 room -> refactor it
    useEffect(() => {
        /** Raised hand subscriptions and unsubscriptions */
        let createRaisedHandSubscription = (
            API.graphql(graphqlOperation(onRaisedHandCreated, { chimeMeetingId: getExternalMeetingId() })) as any
        ).subscribe({
            next: (next: any) => {
                getRaisedHandsByChimeMeetingId(getExternalMeetingId() as string).then((data: any) => {
                    setRaisedHands(
                        data.data.listRaisedHands.items.map((item: any) => {
                            return item.id
                        })
                    )
                })
            },
            error: (error: any) => {
                logger.error({
                    message: "Subscription Error: onRaisedHandCreated",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            },
            complete: () => {
                logger.info("Subscription Complete: onRaisedHandCreated")
            }
        })

        let deleteRaisedHandSubscription = (
            API.graphql(graphqlOperation(onRaisedHandDeleted, { chimeMeetingId: getExternalMeetingId() })) as any
        ).subscribe({
            next: (next: any) => {
                getRaisedHandsByChimeMeetingId(getExternalMeetingId() as string).then((data: any) => {
                    setRaisedHands(
                        data.data.listRaisedHands.items.map((item: any) => {
                            return item.id
                        })
                    )
                })
            },
            error: (error: any) => {
                logger.error({
                    message: "Subscription Error: onRaisedHandDeleted",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            },
            complete: () => {
                logger.info("Subscription Complete: onRaisedHandDeleted")
            }
        })

        return () => {
            createRaisedHandSubscription.unsubscribe()
            deleteRaisedHandSubscription.unsubscribe()
        }
        // eslint-disable-next-line
    }, [getExternalMeetingId()])

    useEffect(() => {
        /** Extending the Chime's getAttendee method, and adding data from our backend */
        meetingManager.getAttendee = async (externalMeetingId: string | undefined, externalUserId: string | undefined) => {
            if (!externalUserId) {
                return
            }
            const user: AttendeeData | BackendServiceError | any = await getAttendeeInfo(
                meetingController.getExternalMeetingId() as string,
                externalUserId as string
            ).then((resp: any) => {
                if ("id" in resp) {
                    return {
                        id: resp.id,
                        name: resp?.name,
                        role: resp?.role,
                        avatarUrl: resp?.avatarUrl,
                        position: resp?.position,
                        positionDe: resp?.positionDe,
                        connectionStatus: resp?.connectionStatus,
                        company: resp?.company,
                        userType: resp?.userType
                    } as AttendeeData
                }
            })

            return user // error
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        /** Initialize the meeting when entering a conference room */
        ;(async () => {
            if (meetingStatus !== MeetingStatus.Succeeded || roomName !== meetingController.getExternalMeetingId()) {
                await meetingController.leaveMeeting(true)
                meetingController.initMeeting(getExternalMeetingIdFromURL(), loggedInUser.user()!.profileId)
            }
        })()
        //eslint-disable-next-line
    }, [roomName])

    useEffect(
        () => {
            /** Generate conference room breadcrumbs using generateBreadcrumb utility function */
            const abortController = new AbortController()
            generateBreadCrumb(roomName, attendees, loggedInUserId).then((breadcrumb) => {
                if (breadcrumb && breadcrumb.length) {
                    setBreadcrumb(breadcrumb)
                    if (meetingController.getMeetingKind() === "call") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[0])
                    } else if (meetingController.getMeetingKind() === "virtualCafe") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[2])
                    } else if (meetingController.getMeetingKind() === "breakout") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[1])
                    } else if (meetingController.getMeetingKind() === "calenderEntry") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[0])
                    } else if (meetingController.getMeetingKind() === "conferenceroom") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[0])
                    } else if (meetingController.getMeetingKind() === "greenroom") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[1])
                    } else if (meetingController.getMeetingKind() === "showroom") {
                        meetingController.setMeetingTitle(breadcrumb.map((bc) => bc.name)[0])
                    }
                }
            })
            return () => abortController.abort()
        },
        // eslint-disable-next-line
        [roomName, branding, attendees, lang]
    )

    useEffect(() => {
        if (meetingController.getMeetingKind() === "greenroom") {
            greenRoom.onGreenRoomEnter("greenroom", meetingController.getExternalMeetingId()?.slice(3) || "")
        }
        // eslint-disable-next-line
    }, [greenRoom, meetingController.getMeetingKind()])

    useEffect(() => {
        // Load the premeeting settings
        if (meetingStatus === MeetingStatus.Succeeded) {
            preMeetingSettingsContext.applyStoredVideoSettings()
            preMeetingSettingsContext.applyStoredAudioSettings()
        }
        // eslint-disable-next-line
    }, [meetingStatus])

    let content: JSX.Element = <div />

    if (getEGMeetingStatus()) {
        switch (getEGMeetingStatus()) {
            case EGMeetingStatus.Full:
                content = (
                    <MeetingStatusMessage
                        onPositiveActionClick={() => {
                            history.push((window.localStorage.getItem("routeBeforeCall") as string) || "/")
                        }}
                        positiveActionButtonText={branding.conferenceTexts.backButtonText}
                        message={branding.conferenceTexts.maxAttendees}
                    />
                )
                break
            case EGMeetingStatus.TimeUp:
                content = (
                    <MeetingStatusMessage
                        onPositiveActionClick={() => {
                            history.push("/")
                        }}
                        positiveActionButtonText={branding.conferenceTexts.backButtonText}
                        messageIcon={IconPhone({ fill: "#fff", width: "18", height: "18" })}
                        message={branding.conferenceTexts.timeUp}
                    />
                )
                break
            case EGMeetingStatus.Kicked:
                content = (
                    <MeetingStatusMessage
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        positiveActionButtonText={branding.conferenceTexts.rejoinButtonText}
                        onNegativeActionClick={() => {}}
                        negativeActionButtonText={branding.conferenceTexts.cancelButtonText}
                        titleIcon={IconRemovedFromMeeting({
                            fill: "#d9d9d9",
                            width: "18",
                            height: "18"
                        })}
                        title={branding.conferenceTexts.statusKickedTitle}
                        message={branding.conferenceTexts.statusKickedDefault}
                        customMessage={meetingController.getKickOrBanMessage()}
                    />
                )
                break
            case EGMeetingStatus.Banned:
                content = (
                    <MeetingStatusMessage
                        onNegativeActionClick={() => {
                            history.push("/")
                        }}
                        negativeActionButtonText={branding.conferenceTexts.backButtonText}
                        titleIcon={IconBannedFromMeeting({
                            fill: "#d9d9d9",
                            width: "25",
                            height: "18"
                        })}
                        title={branding.conferenceTexts.statusBannedTitle}
                        message={branding.conferenceTexts.statusKickedDefault}
                        customMessage={meetingController.getKickOrBanMessage()}
                    />
                )
                break
            case EGMeetingStatus.GreenRoomLive:
                content = (
                    <MeetingStatusMessage
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        positiveActionButtonText={branding.conferenceTexts.reloadButtonText}
                        negativeActionButtonText={branding.conferenceTexts.backButtonText}
                        onNegativeActionClick={() => {
                            history.push("/")
                        }}
                        message={branding.conferenceTexts.statusLive}
                        messageIcon={IconLiveStream({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoModeratorInRoom:
                content = (
                    <MeetingStatusMessage
                        onNegativeActionClick={() => {
                            history.push(window.localStorage.getItem("routeBeforeCall") || "/")
                        }}
                        negativeActionButtonText={branding.conferenceTexts.backButtonText}
                        message={branding.conferenceTexts.noModeratorsInRoomMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoConferenceRoomAccess:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.tryAgainButtonText}
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        onNegativeActionClick={() => {
                            history.push(window.localStorage.getItem("routeBeforeCall") || "/")
                        }}
                        negativeActionButtonText={branding.conferenceTexts.backButtonText}
                        message={branding.conferenceTexts.noAccessToRoomMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoConferenceRoomAccessGranted:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.tryAgainButtonText}
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        negativeActionButtonText={branding.conferenceTexts.cancelButtonText}
                        onNegativeActionClick={() => {}}
                        message={branding.conferenceTexts.conferenceRoomAccessNotGrantedMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoConferenceRoom:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.backButtonText}
                        onPositiveActionClick={() => {
                            history.push(window.localStorage.getItem("routeBeforeCall") || "/")
                        }}
                        message={branding.conferenceTexts.noExsistingConferenceRoom}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoEventdate:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.backButtonText}
                        onPositiveActionClick={() => {
                            history.push(window.localStorage.getItem("routeBeforeCall") || "/")
                        }}
                        message={branding.conferenceTexts.noEventDateContectedToRoundTable}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoAuth:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.backButtonText}
                        onPositiveActionClick={() => {
                            history.push(window.localStorage.getItem("routeBeforeCall") || "/")
                        }}
                        message={branding.conferenceTexts.noAccessToRoomMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
        }
    } else {
        switch (meetingStatus) {
            case MeetingStatus.Failed:
            case MeetingStatus.TerminalFailure:
            case MeetingStatus.Ended:
                content = (
                    <MeetingStatusMessage
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        positiveActionButtonText={branding.conferenceTexts.reloadButtonText}
                        message={branding.conferenceTexts.genericError}
                    />
                )
                break
            case MeetingStatus.Loading:
            case MeetingStatus.Left:
                if (meetingController.getIsSwitchingRoom()) {
                    content = <MeetingStatusMessage message={branding.conferenceTexts.switchingRoomText} />
                } else {
                    content = <MeetingStatusMessage messageIcon={<Spinner animation={"border"} />} />
                }
                break
            case MeetingStatus.Succeeded:
                content = (
                    <>
                        <RaisedHandContext.Provider value={raisedHands}>
                            <TopBar
                                backgroundColor={branding.mainInfoColor ?? "black"}
                                background={branding.topBar.background}
                                textColor="#fff"
                            />
                            <ConferenceRoomContainer>
                                <MainContent>
                                    <BreadcrumbRoot>
                                        <Breadcrumb
                                            breadcrumb={breadcrumb}
                                            noTracking={true}
                                            classification="Details"
                                            contentType="Café"
                                        />
                                    </BreadcrumbRoot>
                                    <Remote>
                                        <Stage />
                                    </Remote>
                                    <Local>
                                        <LocalTileVisibilityToggleButton
                                            isLocalTileVisible={isLocalTileVisibleToUser}
                                            handleClick={() => setIsLocalTileVisibleToUser(!isLocalTileVisibleToUser)}
                                        />
                                        <LocalTile isLocalTileVisibleToUser={isLocalTileVisibleToUser} />
                                        <BottomPanel>
                                            <Timer />
                                            <ControlBar />
                                            <GreenRoomControls />
                                        </BottomPanel>
                                    </Local>
                                </MainContent>
                                <Roster />
                            </ConferenceRoomContainer>
                        </RaisedHandContext.Provider>
                    </>
                )
                break
            default:
                content = (
                    <MeetingStatusMessage
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        positiveActionButtonText={branding.conferenceTexts.reloadButtonText}
                        message={branding.conferenceTexts.genericError}
                    />
                )
        }
    }

    useEffect(() => {
        if (
            meetingManager.meetingStatus === MeetingStatus.Succeeded &&
            !isVideoEnabled &&
            (meetingInvitation.getInvitationType() === InvitationType.VIDEO ||
                meetingInvitation.getAcceptanceType() === InvitationType.VIDEO)
        ) {
            setTimeout(() => {
                toggleVideo()
            }, 500)
        }

        // eslint-disable-next-line
    }, [meetingManager.meetingStatus, meetingInvitation.getAcceptanceType(), meetingInvitation.getInvitationType()])

    const notifyUsersMeetingStarted = useCallback(async () => {
        if (
            meetingController.getMeetingKind() === "calenderEntry" &&
            meetingManager.meetingStatus === MeetingStatus.Succeeded &&
            attendees.length <= 1
        ) {
            let meetingAttendees = await listUsersInAllLoungesOrCalls(meetingController.getExternalMeetingId() || "").then(
                (resp: any) => {
                    return resp[`/meetingV2/${meetingController.getExternalMeetingId()}`].map((attendee: any) => attendee.userId)
                }
            )
            findCalendarEntryById(meetingController.getExternalMeetingId()?.slice(3) || "").then((resp) => {
                if (resp && meetingAttendees) {
                    let attendingParticipants = meetingAttendees
                    let usersToNotify = resp.participants.items
                        .filter(
                            (
                                attendee: any // create a list of users:
                            ) =>
                                attendee.status === InviteStatus.ACCEPTED && // who accepted the invitation
                                !attendingParticipants.includes(attendee.userId) // all users who are not in the current room
                        )
                        .map((invited: any) => invited.userId)

                    if (attendingParticipants && attendingParticipants.length <= 1) {
                        usersToNotify.forEach((user: any) => {
                            meetingInvitation.sendInvite(user, InvitationType.NOTIFY, {
                                meetingId: meetingController.getExternalMeetingId()?.slice(3) || "",
                                meetingKind: "calenderEntry",
                                meetingTitle: resp.title
                            })
                        })
                    }
                }
            })
        }
        // eslint-disable-next-line
    }, [meetingController.getExternalMeetingId(), meetingManager.meetingStatus, attendees])

    useEffect(() => {
        setTimeout(notifyUsersMeetingStarted, 10000)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {branding.configuration.useConferenceRoomV2 ? (
                <ConferenceRoomMainContainer>{content}</ConferenceRoomMainContainer>
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        flexDirection: "column"
                    }}
                >
                    <p>Please enable conference room V2 in the branding.js file</p>
                    <p>
                        <code>useConferenceRoomV2</code>
                    </p>
                </div>
            )}
        </>
    )
}

export default ConferenceRoom
