import { useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { DataMessage } from "amazon-chime-sdk-js"
import { useEffect, useState } from "react"
import branding from "../../../../../branding/branding"
import { ChannelStatus } from "../../../../../conference/enums/ChannelStatus"
import { useGreenRoomContext } from "../../../../context/GreenRoomContext"
import { useMeetingController } from "../../../../context/MeetingController"
import { DataMessageType } from "../../../../enums/DataMessageType"
import { LiveIndicatorRoot } from "./LiveIndicator.styled"
import Point from "./Point/Point"

function LiveIndicator() {
    const greenRoom = useGreenRoomContext()
    const meetingController = useMeetingController()
    const [isLive, setIsLive] = useState(false)
    const meetingManager = useMeetingManager()

    useEffect(() => {
        const status = greenRoom.getChannelStatus()
        setIsLive(status === ChannelStatus.ON_AIR ? true : false)
        // eslint-disable-next-line
    }, [greenRoom.getChannelStatus()])

    useEffect(() => {
        meetingManager.audioVideo?.realtimeSubscribeToReceiveDataMessage(
            meetingController.getExternalMeetingId()?.slice(0, 10) || "",
            (dataMessage: DataMessage) => {
                const messageData = dataMessage.json()
                const messageDataType: DataMessageType = messageData.type
                if (!messageDataType) return
                setIsLive(messageData.data === ChannelStatus.ON_AIR ? true : false)
            }
        )
        // eslint-disable-next-line
    }, [meetingManager.audioVideo])

    if (meetingController.getMeetingKind() !== "greenroom") return null

    return (
        <>
            {isLive ? (
                <LiveIndicatorRoot isLive>
                    {branding.conferenceTexts.onAirMessage} <Point />
                </LiveIndicatorRoot>
            ) : (
                <LiveIndicatorRoot isLive>{branding.conferenceTexts.currentlyNotLiveMessage}</LiveIndicatorRoot>
            )}
        </>
    )
}

export default LiveIndicator
