import {
    useRosterState,
    useActiveSpeakersState,
    useRemoteVideoTileState,
    useMeetingStatus,
    MeetingStatus,
    useMeetingManager
} from "amazon-chime-sdk-component-library-react"
import { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import branding from "../../../../branding/branding"
import { useLoggedInState } from "../../../../globalStates/LoggedInUser"
import { IconPhone, IconRemovedFromMeeting, IconBannedFromMeeting, IconLiveStream, IconBlockContact } from "../../../../ui/Icons"
import MeetingStatusMessage, { MeetingStatusMessageSize } from "../../../ConferenceRoom/MeetingStatusMessage/MeetingStatusMessage"
import { useMeetingController } from "../../../context/MeetingController"
import { EGMeetingStatus } from "../../../enums/EGMeetingStatus"
import { RemoteTileMini } from "../RemoteTileMini/RemoteTileMini"
import { MinimizedLayoutRoot } from "./MinimizedLayout.styled"

interface MinimizedLayoutProps {
    isMouseHovering: boolean
}

export function MinimizedLayout(props: MinimizedLayoutProps) {
    const loggedInState = useLoggedInState()
    const { roster } = useRosterState()
    const activeSpeakers = useActiveSpeakersState()
    const [attendees, setAttendees] = useState(
        Object.values(roster).filter((attendee: any) => attendee?.id !== loggedInState.user()?.profileId)
    )
    const { attendeeIdToTileId } = useRemoteVideoTileState()
    const [activeSpeaker, setActiveSpeaker] = useState<any>()
    const { getEGMeetingStatus } = useMeetingController()
    const meetingStatus = useMeetingStatus()
    const meetingController = useMeetingController()
    const meetingManager = useMeetingManager()
    const [activeSpeakerId, setActiveSpeakerId] = useState<any>()
    const [lastActiveSpeakerId, setLastActiveSpeakerId] = useState<any>()
    const history = useHistory()

    useEffect(() => {
        setAttendees(Object.values(roster).filter((attendee: any) => attendee?.id !== loggedInState.user()?.profileId))
        // eslint-disable-next-line
    }, [roster])

    useEffect(() => {
        setActiveSpeakerId(
            activeSpeakers.filter(
                (attendee: string) => attendee !== meetingManager.meetingSessionConfiguration?.credentials?.attendeeId
            )[0]
        )
        // eslint-disable-next-line
    }, [activeSpeakers])

    useEffect(() => {
        setLastActiveSpeakerId(activeSpeaker)
    }, [activeSpeaker])

    useEffect(() => {
        // Logic for showing the last active user
        if (!activeSpeakerId) {
            setActiveSpeaker(attendees[0])
        } else if (activeSpeakerId) {
            setActiveSpeaker(attendees.filter((attendee: any) => attendee?.chimeAttendeeId === activeSpeakerId)[0])
        }
    }, [activeSpeakerId, lastActiveSpeakerId, activeSpeakers, attendees, attendeeIdToTileId])

    let content: JSX.Element = <MinimizedLayoutRoot />

    if (getEGMeetingStatus()) {
        switch (getEGMeetingStatus()) {
            case EGMeetingStatus.Full:
                content = (
                    <MeetingStatusMessage size={MeetingStatusMessageSize.SMALL} message={branding.conferenceTexts.maxAttendees} />
                )
                break
            case EGMeetingStatus.TimeUp:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        messageIcon={IconPhone({ fill: "#fff", width: "18", height: "18" })}
                        message={branding.conferenceTexts.timeUp}
                    />
                )
                break
            case EGMeetingStatus.Kicked:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.rejoinButtonText}
                        onPositiveActionClick={() => {
                            history.push("/meetingV2/" + meetingController.getExternalMeetingId())
                        }}
                        size={MeetingStatusMessageSize.SMALL}
                        titleIcon={IconRemovedFromMeeting({
                            fill: "#d9d9d9",
                            width: "18",
                            height: "18"
                        })}
                        title={branding.conferenceTexts.statusKickedTitle}
                        message={branding.conferenceTexts.statusKickedDefault}
                        customMessage={meetingController.getKickOrBanMessage()}
                    />
                )
                break
            case EGMeetingStatus.Banned:
                content = (
                    <MeetingStatusMessage
                        positiveActionButtonText={branding.conferenceTexts.closeBtnText}
                        onPositiveActionClick={() => {
                            meetingController.cleanUp()
                        }}
                        size={MeetingStatusMessageSize.SMALL}
                        titleIcon={IconBannedFromMeeting({
                            fill: "#d9d9d9",
                            width: "25",
                            height: "18"
                        })}
                        title={branding.conferenceTexts.statusBannedTitle}
                        message={branding.conferenceTexts.statusKickedDefault}
                        customMessage={meetingController.getKickOrBanMessage()}
                    />
                )
                break
            case EGMeetingStatus.GreenRoomLive:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.statusLive}
                        messageIcon={IconLiveStream({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoModeratorInRoom:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.noModeratorsInRoomMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoConferenceRoomAccess:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.noAccessToRoomMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoConferenceRoomAccessGranted:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.conferenceRoomAccessNotGrantedMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoConferenceRoom:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.noExsistingConferenceRoom}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoEventdate:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.noEventDateContectedToRoundTable}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
            case EGMeetingStatus.NoAuth:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        message={branding.conferenceTexts.noAccessToRoomMessage}
                        messageIcon={IconBlockContact({
                            fill: "#fff",
                            width: "18",
                            height: "18"
                        })}
                    />
                )
                break
        }
    } else {
        switch (meetingStatus) {
            case MeetingStatus.Failed:
            case MeetingStatus.TerminalFailure:
            case MeetingStatus.Ended:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        onPositiveActionClick={() => {
                            window.location.reload()
                        }}
                        positiveActionButtonText={branding.conferenceTexts.reloadButtonText}
                        message={branding.conferenceTexts.genericError}
                    />
                )
                break
            case MeetingStatus.Loading:
                content = (
                    <MeetingStatusMessage size={MeetingStatusMessageSize.SMALL} messageIcon={<Spinner animation={"border"} />} />
                )
                break
            case MeetingStatus.Left:
                if (meetingController.getIsSwitchingRoom()) {
                    content = (
                        <MeetingStatusMessage
                            size={MeetingStatusMessageSize.SMALL}
                            message={branding.conferenceTexts.switchingRoomText}
                        />
                    )
                } else {
                    content = (
                        <MeetingStatusMessage
                            size={MeetingStatusMessageSize.SMALL}
                            message={branding.conferenceTexts.callEnded}
                            messageIcon={IconPhone({ fill: "#fff", width: "18", height: "18" })}
                        />
                    )
                }

                break
            case MeetingStatus.Succeeded:
                if (attendees.length) {
                    content = (
                        <RemoteTileMini
                            isMouseHovering={props.isMouseHovering}
                            attendeeToTileId={attendeeIdToTileId}
                            isVideoTile={Object.keys(attendeeIdToTileId).includes(activeSpeakerId)}
                            attendee={activeSpeaker}
                        />
                    )
                } else {
                    content = (
                        <MeetingStatusMessage
                            size={MeetingStatusMessageSize.SMALL}
                            message={branding.conferenceTexts.noAttendees}
                        />
                    )
                }
                break
            default:
                content = (
                    <MeetingStatusMessage
                        size={MeetingStatusMessageSize.SMALL}
                        onPositiveActionClick={() => {
                            history.push("/meetingV2/" + meetingController.getExternalMeetingId())
                        }}
                        positiveActionButtonText={branding.conferenceTexts.rejoinButtonText}
                        message={branding.conferenceTexts.genericError}
                    />
                )
        }
    }

    return <MinimizedLayoutRoot>{content}</MinimizedLayoutRoot>
}
